import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { AvailableLoadSearchHistory } from 'shared/models/loads/available-load-search-history.model';
import { SearchHistoryLocations } from 'shared/models/recent-searches/search-history-locations.model';

// V2 using redis
export const FETCH_SEARCH_HISTORY_LOCATIONS = `${REQUEST_START}:FETCH_SEARCH_HISTORY_LOCATIONS`;
export const FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS = `${REQUEST_SUCCESS}:FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS`;
export const FETCH_SEARCH_HISTORY_LOCATIONS_FAILURE = `${REQUEST_FAILURE}:FETCH_SEARCH_HISTORY_LOCATIONS_FAILURE`;

export const fetchSearchHistoryLocations = () => ({type: FETCH_SEARCH_HISTORY_LOCATIONS});
export const fetchSearchHistoryLocationsSucceeded = (payload: SearchHistoryLocations) => ({type: FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS, payload});
export const fetchSearchHistoryLocationsFailed = (error: AjaxError) => ({type: FETCH_SEARCH_HISTORY_LOCATIONS_FAILURE, error});

// V1
export const FETCH_SEARCH_HISTORY = `${REQUEST_START}:FETCH_SEARCH_HISTORY`;
export const FETCH_SEARCH_HISTORY_SUCCESS = `${REQUEST_SUCCESS}:FETCH_SEARCH_HISTORY_SUCCESS`;
export const FETCH_SEARCH_HISTORY_FAILURE = `${REQUEST_FAILURE}:FETCH_SEARCH_HISTORY_FAILURE`;

export const fetchSearchHistory = () => ({type: FETCH_SEARCH_HISTORY});
export const fetchSearchHistorySucceeded = (payload: AvailableLoadSearchHistory[]) => ({type: FETCH_SEARCH_HISTORY_SUCCESS, payload});
export const fetchSearchHistoryFailed = (error: AjaxError) => ({type: FETCH_SEARCH_HISTORY_FAILURE, error});

export const SAVE_SEARCH_HISTORY = `${REQUEST_START}:SAVE_SEARCH_HISTORY`;
export const SAVE_SEARCH_HISTORY_SUCCESS = `${REQUEST_SUCCESS}:SAVE_SEARCH_HISTORY_SUCCESS`;
export const SAVE_SEARCH_HISTORY_FAILURE = `${REQUEST_FAILURE}:SAVE_SEARCH_HISTORY_FAILURE`;

export const saveSearchHistory = (entry: AvailableLoadSearchHistory) => ({type: SAVE_SEARCH_HISTORY, entry});
export const saveSearchHistorySucceeded = (payload: AvailableLoadSearchHistory) => ({type: SAVE_SEARCH_HISTORY_SUCCESS, payload});
export const saveSearchHistoryFailed = (error: AjaxError) => ({type: SAVE_SEARCH_HISTORY_FAILURE, error});

export const DELETE_SEARCH_HISTORY = `${REQUEST_START}:DELETE_SEARCH_HISTORY`;
export const DELETE_SEARCH_HISTORY_SUCCESS = `${REQUEST_SUCCESS}:DELETE_SEARCH_HISTORY_SUCCESS`;
export const DELETE_SEARCH_HISTORY_FAILURE = `${REQUEST_FAILURE}:DELETE_SEARCH_HISTORY_FAILURE`;

export const deleteSearchHistory = (entry: AvailableLoadSearchHistory) => ({type: DELETE_SEARCH_HISTORY, entry});
export const deleteSearchHistorySucceeded = () => ({type: DELETE_SEARCH_HISTORY_SUCCESS});
export const deleteSearchHistoryFailed = (error: AjaxError) => ({type: DELETE_SEARCH_HISTORY_FAILURE, error});

export const CLEAR_SEARCH_HISTORY = `${REQUEST_START}:CLEAR_SEARCH_HISTORY`;
export const CLEAR_SEARCH_HISTORY_SUCCESS = `${REQUEST_SUCCESS}:CLEAR_SEARCH_HISTORY_SUCCESS`;
export const CLEAR_SEARCH_HISTORY_FAILURE = `${REQUEST_FAILURE}:CLEAR_SEARCH_HISTORY_FAILURE`;

export const clearSearchHistory = () => ({type: CLEAR_SEARCH_HISTORY});
export const clearSearchHistorySucceeded = () => ({type: CLEAR_SEARCH_HISTORY_SUCCESS});
export const clearSearchHistoryFailed = (error: AjaxError) => ({type: CLEAR_SEARCH_HISTORY_FAILURE, error});

export const SHOW_SEARCH_HISTORY = 'SHOW_SEARCH_HISTORY';
export const showSearchHistory = () => ({type: SHOW_SEARCH_HISTORY});

export const HIDE_SEARCH_HISTORY = 'HIDE_SEARCH_HISTORY';
export const hideSearchHistory = () => ({type: HIDE_SEARCH_HISTORY});

export const SELECT_SEARCH_HISTORY = 'SELECT_SEARCH_HISTORY';
export const selectSearchHistory = (entry: AvailableLoadSearchHistory) => ({type: SELECT_SEARCH_HISTORY, entry});

export const MARK_SEARCH_HISTORY_PREFERRED = 'MARK_SEARCH_HISTORY_PREFERRED';
export const markSearchHistoryPreferred = (entry: AvailableLoadSearchHistory) => ({type: MARK_SEARCH_HISTORY_PREFERRED, entry});

export const EDIT_SEARCH_HISTORY = 'EDIT_SEARCH_HISTORY';
export const editSearchHistory = (entry: AvailableLoadSearchHistory) => ({type: EDIT_SEARCH_HISTORY, entry});

export const FILTER_SEARCH_HISTORY = 'FILTER_SEARCH_HISTORY';
export const filterSearchHistory = (filter: string) => ({type: FILTER_SEARCH_HISTORY, filter});

export interface SelectSearchHistoryAction extends Action { entry: AvailableLoadSearchHistory; }
